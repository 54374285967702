export function dataPerCalendarWeekChart() {
  this.chartOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ["#005181"],
    xaxis: {
      categories: []
    },
    yaxis: {
      title: {
        text: null
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        }
      }
    }
  };
}

export function dataFileImportTypeCategorizationChart() {
  this.chartOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "70%",
        endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ["#34c38f", "#ffcc44"],
    xaxis: {
      categories: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    },
    yaxis: {
      title: {
        text: null
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        }
      }
    }
  };
}

export function dataRecordsPerQuarterChart() {
  this.chartOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "70%",
        endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ["#005181", "#f46a6a"],
    xaxis: {
      categories: ["Q1", "Q2", "Q3", "Q4"],
    },
    yaxis: {
      title: {
        text: null
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        }
      }
    }
  };
}