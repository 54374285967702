import { ApiCall } from "../apiBase.js";

class StatService {
  async getDataRecordsPerCalendarWeek(year) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getDataRecordsPerCalendarWeek($year: Int!) {
            getDataRecordsPerCalendarWeek(year: $year) {
              week
              numberOfDataRecords
            }
          }
        `,
        variables :{
          year: year
        }
      }
    });

    if(response?.data?.data?.getDataRecordsPerCalendarWeek && !response?.data?.errors) {
      return response?.data?.data?.getDataRecordsPerCalendarWeek;
    } else {
      return response?.data?.errors[0];
    }
  }

  async getDatafilesPerCalendarWeek(year, company) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getDatafilesPerCalendarWeek($year: Int!, $company: ID) {
            getDatafilesPerCalendarWeek(year: $year, company: $company) {
              week
              numberOfDatafiles
            }
          }
        `,
        variables :{
          year: year,
          company: company
        }
      }
    });

    if(response?.data?.data?.getDatafilesPerCalendarWeek && !response?.data?.errors) {
      return response?.data?.data?.getDatafilesPerCalendarWeek;
    } else {
      return response?.data?.errors[0];
    }
  }

  async getDatafileImportTypeCategorization(year, company) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getDatafileImportTypeCategorization($year: Int!, $company: ID) {
            getDatafileImportTypeCategorization(year: $year, company:$company) {
              month
              numberOfManualImports
              numberOfLiveImports
            }
          }
        `,
        variables :{
          year: year,
          company: company
        }
      }
    });

    if(response?.data?.data?.getDatafileImportTypeCategorization && !response?.data?.errors) {
      return response?.data?.data?.getDatafileImportTypeCategorization;
    } else {
      return response?.data?.errors[0];
    }
  }

  async getDataRecordsImportTypeCategorization(year, company) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getDataRecordsImportTypeCategorization($year: Int!, $company: ID) {
            getDataRecordsImportTypeCategorization(year: $year, company:$company) {
              month
              numberOfManualDataRecords
              numberOfLiveDataRecords
            }
          }
        `,
        variables :{
          year: year,
          company: company
        }
      }
    });

    if(response?.data?.data?.getDataRecordsImportTypeCategorization && !response?.data?.errors) {
      return response?.data?.data?.getDataRecordsImportTypeCategorization;
    } else {
      return response?.data?.errors[0];
    }
  }

  async getNumberOfDataRecordsPerQuarter(year) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getNumberOfDataRecordsPerQuarter($year: Int!) {
            getNumberOfDataRecordsPerQuarter(year: $year) {
              quarter
              numberOfTotalDataRecords
              numberOfBadDataRecords
            }
          }
        `,
        variables :{
          year: year
        }
      }
    });

    if(response?.data?.data?.getNumberOfDataRecordsPerQuarter && !response?.data?.errors) {
      return response?.data?.data?.getNumberOfDataRecordsPerQuarter;
    } else {
      return response?.data?.errors[0];
    }
  }
}

export default new StatService();